import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import WelcomeLandingScreenV1 from "./components/v1";
import WelcomeLandingScreenV2 from "./components/v2";

interface WelcomeLandingScreenProps {
  onContinue: () => void;
  onSignUp: () => void;
}

export default function WelcomeLandingScreen({
  onContinue,
  onSignUp,
}: WelcomeLandingScreenProps) {
  return isFeatureEnabled(FeatureToggleIdentifier.ENABLE_OPTIMISE_SIGN_IN) ? (
    <WelcomeLandingScreenV2 onContinue={onContinue} onSignUp={onSignUp} />
  ) : (
    <WelcomeLandingScreenV1 onContinue={onContinue} onSignUp={onSignUp} />
  );
}
