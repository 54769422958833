import { Box, Button, Fade, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import womanSmilingImage from "./woman-smiling.png";
import { trackEvent } from "../../../../services/analytics-adapter";
import { useHealthCheck } from "../../../../services/core-api-adapter";
import { GetHelpFABConfig } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import FullscreenBackground from "../../../FullscreenBackground";
import GetHelpCTAButton from "../../../GetHelpCTAButton";
import IconLoader from "../../../IconLoader";

interface WelcomeLandingScreenV1Props {
  onContinue: () => void;
  onSignUp: () => void;
}

export default function WelcomeLandingScreenV1({
  onContinue,
  onSignUp,
}: WelcomeLandingScreenV1Props) {
  const { t } = useTranslation();
  const { data, isFetching } = useHealthCheck();

  function onSignInButtonClick() {
    trackEvent({
      event: "action.signInButtonClicked",
      source: "welcomeFlow.landing",
    });
    onContinue();
  }

  function onSignUpButtonClick() {
    trackEvent({
      event: "action.signUpButtonClicked",
      source: "welcomeFlow.landing",
    });
    onSignUp();
  }

  let buttonsOrAPIError = (
    <>
      <Typography variant="body1" align="center">
        {t("common.APINotAvailable")}
      </Typography>
    </>
  );

  if (isFetching) {
    buttonsOrAPIError = <></>;
  }

  if (!isFetching && data) {
    buttonsOrAPIError = (
      <Stack direction="row" spacing={2}>
        <Button color="info" fullWidth onClick={onSignInButtonClick}>
          {t("common.signInButton")}
        </Button>
        <Button color="primary" fullWidth onClick={onSignUpButtonClick}>
          {t("common.signUpButton")}
        </Button>
      </Stack>
    );
  }

  return (
    <Stack flexGrow={1} minHeight="100%">
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        spacing={2}
        minHeight="100%"
        sx={{
          p: 2,
          color: "primary.contrastText",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Stack spacing={2}>
          <IconLoader icon="UnuLogo" sx={{ fontSize: convertPxToRem(90) }} />
          <Stack
            spacing={2}
            color="info"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography color="inherit" variant="h1">
              {t("WelcomeLandingScreen.title")}
            </Typography>
            <Typography color="inherit" variant="body1">
              {t("WelcomeLandingScreen.message")}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <Fade in={true}>
            <Box
              component="img"
              position="absolute"
              bottom={0}
              sx={{
                width: "100%",
              }}
              alt={t("WelcomeLandingScreen.womanSmilingImageAlt")}
              src={womanSmilingImage}
            />
          </Fade>
        </Stack>

        <Fade in={!isFetching}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              pt={1}
            >
              <GetHelpCTAButton
                eventName="action.contactSupportFab"
                buttonColor="info"
              />
            </Stack>

            {buttonsOrAPIError}
          </Stack>
        </Fade>
      </Stack>

      <Box>
        <FullscreenBackground color="primary.main" />
      </Box>
    </Stack>
  );
}
